@font-face {
  font-family: "Monaco_";
  size-adjust: 87.5%;
  src: local("Monaco");
}

html,
body {
  margin: 0px;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body > sp-theme {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

sp-switch {
  width: 26px;
  --spectrum-switch-cursor-hit-x: 0px;
  --spectrum-switch-m-handle-border-size: 1px;
}

.spectrum-Table {
  --spectrum-table-m-regular-row-background-color-down: var(
    --spectrum-global-color-gray-200
  );
  --spectrum-table-m-regular-row-background-color-selected: var(
    --spectrum-global-color-gray-200
  );
}

.spectrum-Table-row.focus-visible {
  background-color: var(
    --spectrum-table-m-regular-row-background-color-hover,
    var(--spectrum-alias-highlight-hover)
  );
}

.spectrum-Table-row.focus-visible:active {
  background-color: var(
    --spectrum-table-m-regular-row-background-color-down,
    var(--spectrum-alias-highlight-down)
  );
}

.spectrum-Table-row.focus-visible::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: 1px;
  bottom: 0px;
  left: 1px;
  right: 1px;
  box-shadow: inset 0 0 0 2px var(--spectrum-alias-focus-ring-color);
  border-radius: var(--spectrum-table-regular-cell-border-radius-key-focus);
}

.spectrum-Table
  tbody.spectrum-Table-body:last-of-type
  .spectrum-Table-row:last-of-type::after {
  bottom: 1px;
}

.spectrum-Table
  tbody.spectrum-Table-body:last-of-type
  .spectrum-Table-row:last-of-type
  .spectrum-Table-cell {
  border-bottom: 1px solid
    var(
      --spectrum-table-m-regular-border-color,
      var(--spectrum-alias-border-color-mid)
    );
}

.spectrum-Table
  tbody.spectrum-Table-body:not(:first-of-type)
  .spectrum-Table-row:first-of-type
  .spectrum-Table-cell:first-child {
  border-top-left-radius: unset !important;
}

.spectrum-Table
  tbody.spectrum-Table-body:not(:first-of-type)
  .spectrum-Table-row:first-of-type
  .spectrum-Table-cell:last-child {
  border-top-right-radius: unset !important;
}

.spectrum-Table
  tbody.spectrum-Table-body:not(:last-of-type)
  .spectrum-Table-row:last-of-type
  .spectrum-Table-cell:first-child {
  border-bottom-left-radius: unset !important;
}

.spectrum-Table
  tbody.spectrum-Table-body:not(:last-of-type)
  .spectrum-Table-row:last-of-type
  .spectrum-Table-cell:last-child {
  border-bottom-right-radius: unset !important;
}

.spectrum-Table
  tbody.spectrum-Table-body:last-of-type
  .spectrum-Table-row:last-of-type
  .spectrum-Table-cell:first-child {
  border-bottom-left-radius: var(--spectrum-table-regular-border-radius);
}

.spectrum-Table
  tbody.spectrum-Table-body:last-of-type
  .spectrum-Table-row:last-of-type
  .spectrum-Table-cell:last-child {
  border-bottom-right-radius: var(--spectrum-table-regular-border-radius);
}
